import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { List } from "@mastodonlm/shared";

type DeleteListDialogProps = {
  open: boolean;
  list: List;
  handleClose: () => void;
  handleDelete: (list: List) => void;
};

function DeleteListDialog({
  open,
  list,
  handleClose,
  handleDelete,
}: DeleteListDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm list deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the list <b>{list.title}</b>?{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={() => handleDelete(list)}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteListDialog;
